'use client'
import React, { useEffect, useMemo, Suspense } from 'react'
import { usePathname } from 'next/navigation'
import { ChatCircleDots } from 'images'
import Link from 'components/Link'
import Hamburger from 'components/Hamburger'
import HamburgerH5 from 'components/Hamburger/Hamburger_h5'
import Image from 'next/image'
import { getCookie, handleUserCookiesConfig, setCookie, userKey } from 'helpers/cookies'
import styles from '../Header.module.scss'
import NavLogo from '../Common/NavLogo'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { postNotification } from 'bossjob-remote/dist/clientStorage'

const ProtectedHeaderMobile = ({ lang, langKey, position = false, pageType }: any) => {
  const pathname = usePathname()
  // const user = getCookie(userKey)
  const userInfo = useSharedData('ME')
  const unread = useSharedData('CHAT_TOTAL_UNREAD')
  const totalUnread = typeof unread === 'object' ? 0 : unread

  let user = null
  try {
    const data = getCookie(userKey)
    user = typeof data == 'string' ? JSON.parse(data) : data
  } catch (error) {
    user = {}
  }

  const userProfile = useMemo(() => {
    return {
      ...user,
      ...userInfo
    }
  }, [userInfo])

  useEffect(() => {
    const sessionid = getCookie('sessionid')
    if (sessionid) {
      postNotification('FETCH_ME')
    }
  }, [])


  useEffect(() => {
    if (userInfo?.id) {
      const userCookies = handleUserCookiesConfig(userInfo)
      setCookie(userKey, userCookies)
    }
  }, [userInfo])
  return (
    <div
      className={`${styles.header} ${position && styles.headerPosition} ${pageType === 'h5' && styles.header_h5
        }`}
    >
      {/* <MaintainNotification langKey={langKey} lang={lang} /> */}
      <nav
        className={`${styles.headerContainer} ${styles.headerContainer_defaultH5} ${pageType === 'h5' && styles.headerContainer_h5}`}
      >
        {/* logo */}
        <NavLogo langKey={langKey} isMobile={true} pageType={pageType} />
        {/* mobile */}
        {pageType !== 'h5' && (
          <div className={styles.mobileIconWrapper}>
            {!pathname.includes('/chat/[chat_id]') ? (
              <li
                className={styles.headerLink}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  position: 'relative',
                  left: 30
                  // top: 5
                }}
              >
                <Link title='Jobs' to={'/' + langKey + '/chat/list'}>
                  <Image width={28} height={28} src={ChatCircleDots} alt='Chat logo' />
                  <Suspense>
                    {totalUnread ? (
                      <span
                        className={styles.unread}
                        style={{ position: 'absolute', bottom: '50%', right: '50%' }}
                      >
                        {Number(totalUnread) > 99 ? '99+' : totalUnread}
                      </span>
                    ) : null}
                  </Suspense>
                </Link>
              </li>
            ) : null}
            <div
              className={styles.icon}
            // onClick={() => postNotification('SHOW_SIDE_GUIDE_MODAL')}
            >
              <Hamburger lang={lang} userInfo={userProfile} isLogin={true} />
            </div>
          </div>
        )}

        {pageType === 'h5' ? (
          <HamburgerH5 lang={lang} userInfo={userProfile} isLogin={true} />
        ) : null}

      </nav>
    </div>
  )
}

export default ProtectedHeaderMobile
