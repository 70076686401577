/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
'use client'
import { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import JobDetail from '../JobDetail/JobDetail'
import styles from 'app/index.module.scss'
import { SxProps, Theme } from '@mui/system'
import Link from 'components/Link'

import { throttle } from 'lodash-es'
import { backTopBtn } from 'images/svg'
import MuiTabs from '@mui/material/Tabs'

import vip_user_icon_url from './vip_user_icon.png'

import { addEventTag } from 'helpers/utilities'
import { reportTrackerJobCounts } from 'components/ReportTracker/JobCountTracker'
import { jobCountSourceTracker } from 'components/ReportTracker/util'

const jobsForMeTags = [
  "hp_job_for_you_classification_click",
  "hp_job_for_you_pre_click",
  "hp_job_for_you_card_click",
  "hp_job_for_you_more_click"
]

const popularJobsTags = [
  "hp_popular_jobs_classification_click",
  "hp_popular_jobs_more_click"
]

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderBottom: '0.5px solid #BCBCBC'
        },
        scrollButtons: {
          borderRadius: '50px',
          background: '#fff',
          width: '24px',
          height: '24px'
        },
        indicator: {
          borderRadius: '5px',
          height: '3px'
        }
      }
    }
  }
})

interface StyledTabsProps {
  children?: React.ReactNode
  value: number | string
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void
  variant?: 'standard' | 'scrollable' | 'fullWidth'
  scrollButtons: boolean | 'auto'
  // langKey: string
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '&.MuiBox-root': {
    width: '100%'
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '87.3px',
    width: '100%',
    backgroundColor: '#136FD3',
    borderRadius: '5px'
  }
})

interface StyledTabProps {
  key: string
  label: string
  value: string
  sx: SxProps<Theme>
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ }) => ({
  '&.Mui-selected': {
    color: '#1D2129',
    fontWeight: '700'
  },
  '&:hover': {
    color: '#1D2129'
  }
}))

const Tabs = (props: {
  langKey: string
  sessionid: string
  initTabs: any[]
  initTabValue: string
  initTabList: any[]
  hasJobPreference: boolean
  popular_jobs: any
  recommend_jobs: any
  isRecommendTab: boolean
}) => {
  const {
    langKey,
    sessionid,
    initTabs,
    initTabValue,
    initTabList,
    hasJobPreference,
    isRecommendTab,
    popular_jobs = {},
    recommend_jobs = {}
  } = props

  // console.log('initTab', {
  //   initTabs, initTabValue, isRecommendTab,
  //   initTabList, hasJobPreference,
  //   popular_jobs, recommend_jobs
  // })

  const isLogin = !!sessionid
  const [showBtn, setShowBtn] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<any>(isRecommendTab)
  const [value, setValue] = useState<string>(initTabValue)
  const [list, setList] = useState<Array<any>>(initTabList)

  useEffect(() => {
    window.addEventListener('scroll', useFn)
    trackerJobCounts(initTabList)
    return () => {
      window.removeEventListener('scroll', useFn)
    }
  }, [])

  const useFn = throttle(() => {
    getScrollTop()
  }, 500)

  function trackerJobCounts(jobs) {
    if (!jobs?.length) return
    const jobsIds = jobs.map((item) => item?.job_id).join(',')
    reportTrackerJobCounts({ source: jobCountSourceTracker.home, job_ids: jobsIds })
  }

  const getScrollTop = () => {
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop
    if (scrollTopHeight > 960) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // console.log('newValue', newValue)
    setValue(newValue)

    const allList = popular_jobs?.tabs || recommend_jobs?.tabs || []

    if (allList.length) {
      const currentList = allList[Number(newValue) - 1]?.jobs || []
      setList([...currentList])
      const currentTab = initTabs.filter((item) => item.value == newValue)
      setCurrentTab(currentTab[0])
      trackerJobCounts(currentList)
    }

    if (!isLogin) {
      addEventTag(popularJobsTags[0])
    }

    if (isLogin) {
      addEventTag(jobsForMeTags[0])
    }
  }

  const scrollTopFun = () => {
    document.documentElement.scrollIntoView({
      behavior: 'smooth'
    })
  }

  const toLink = () => {
    if (isLogin) {
      return '/my-jobs'
    } else {
      const isRecommendTab = currentTab?.is_recommend
      return isRecommendTab ? '/personalized-job-picks' : '/get-started'
    }
  }

  return (
    <div className={styles.popularJobsBox}>
      <h2 className={styles.jobTitle}>{isLogin ? recommend_jobs?.title : popular_jobs?.title}</h2>
      <div className={styles.webTab}>
        <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper', backgroundColor: 'transparent' }}>
          <TabContext value='1'>
            <ThemeProvider theme={theme}>
              <StyledTabs
                value={value}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
                onChange={handleChange}
              >
                {initTabs.map((item) => (
                  <StyledTab
                    key={item.value}
                    label={item.tab}
                    value={item.value}
                    sx={{
                      fontSize: '16px',
                      textTransform: 'capitalize',
                      color: '#4E5969',
                      fontFamily: 'product sans',
                      letterSpacing: '1px',
                      width: 'auto',
                      padding: '12px 0',
                      marginRight: '51px',
                      fontWeight: '400'
                    }}
                  />
                ))}
              </StyledTabs>
              {isLogin ? (
                <div className={styles.preference}>
                  {hasJobPreference ? (
                    <div className={styles.preferenceBox}>
                      {recommend_jobs?.user_avatar ? (
                        !recommend_jobs?.is_vip ? (
                          <img src={recommend_jobs?.user_avatar} />
                        ) : (
                          <div className={styles.vipAvatar}>
                            <img
                              src={vip_user_icon_url.src}
                              width='15'
                              height='6'
                              alt=''
                              style={{
                                position: 'absolute',
                                bottom: '-2px',
                                right: '-17px',
                                border: 0,
                                borderRadius: 0,
                                height: 'auto'
                              }}
                            />
                            <img src={recommend_jobs?.user_avatar} />
                          </div>
                        )
                      ) : null}
                      <span style={{ marginRight: '5px' }}>{recommend_jobs?.recommend_text}</span>
                      <Link
                        href={`${langKey}/manage-profile?tab=job-preferences`}
                        className={styles.link}
                        onClick={() => {
                          addEventTag(jobsForMeTags[1])
                        }}
                      >
                        {recommend_jobs?.recommend_highlight_text}
                      </Link>
                    </div>
                  ) : (
                    <p>
                      {recommend_jobs?.recommend_text}{' '}
                      <Link
                        href={`${langKey}/manage-profile?tab=job-preferences`}
                        className={styles.link2}
                      >
                        {recommend_jobs?.recommend_highlight_text}
                      </Link>
                    </p>
                  )}
                </div>
              ) : null}
            </ThemeProvider>

            <div className={styles.tabContainer}>
              {
                list?.map((item) => (
                  <JobDetail
                    langKey={langKey}
                    key={item.job_id}
                    detail={item}
                  />
                ))
              }

              <div className={styles.tabContainer_more} onClick={() => {
                if (!isLogin) {
                  addEventTag(popularJobsTags[2])
                }

                if (isLogin) {
                  addEventTag(jobsForMeTags[3])
                }
              }}>
                <Link className={styles.moreBtn} href={toLink()}>
                  {recommend_jobs?.more_text || popular_jobs?.more_text}
                </Link>
              </div>
            </div>
          </TabContext>
        </Box>
      </div>
      {
        showBtn && (
          <div className={styles.backBtn} onClick={() => scrollTopFun()}>
            {backTopBtn}
          </div>
        )
      }
    </div >
  )
}

export default Tabs


